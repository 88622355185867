import React, { FC } from 'react'
import { SectionTitleWithIcon } from 'src/uikit'
import {
  Box,
  Container,
  useBreakpointValue,
  StyleProps,
} from '@chakra-ui/react'
import Carousel from '../common/Carousel'
import { Offer } from '.'

interface Props {
  title?: string
  href?: string
  action?: string
  alt?: string
  offers: Array<OfferGlobal.RentOfferItem>
  target?: '_self' | '_blank'
  numPerView?: number
  w?: string | number
  bgColor?: string
}

const HomeOffersList: FC<Props & StyleProps> = ({
  title,
  href,
  action,
  alt,
  offers,
  target,
  numPerView,
  w,
  bgColor,
  ...others
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box bgColor={bgColor || 'white'}>
      <Container as={Box} maxW={w || '8xl'} pt={10} {...others} id="offers-list-rent" px="0">
        {title && <SectionTitleWithIcon title={title} action={action} href={href} alt={alt} target={target} />}
        <Carousel
          withCustomNavigation={!isMobile}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 18,
              centeredSlides: true
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: numPerView ? Math.ceil(numPerView / 2) : 3,
              spaceBetween: 30
            },
            1280: {
              slidesPerView: numPerView || 4,
              spaceBetween: 30
            }
          }}
          withScrollbar
          className={isMobile ? 'has-partial-slide' : null}
        >
          {offers.map(offer => {
            return (
              <Carousel.Item key={offer.code}>
                <Offer
                  item={offer}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
      </Container>
    </Box>
  )
}

export default HomeOffersList
